/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "stdout.fm 13번째 로그에서는 Read the Docs, Write the Docs, 도커를 사용하는 이유에 대해서 이야기를 나눴습니다."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://readthedocs.org/"
  }, "Home | Read the Docs")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://blog.readthedocs.com/read-the-docs-2018-stats/"
  }, "Read the Docs 2018 Stats — Read the Docs Blog")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/sphinx-doc/sphinx"
  }, "sphinx-doc/sphinx: Main repository for the Sphinx documentation builder")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://docutils.sourceforge.net/rst.html"
  }, "reStructuredText")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://ruby-doc.org/"
  }, "Ruby-Doc.org: Documenting the Ruby Language")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/realpython/python-guide"
  }, "realpython/python-guide: Python best practices guidebook, written for humans.")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://docs.readthedocs.io/en/latest/intro/getting-started-with-sphinx.html"
  }, "Getting Started with Sphinx — Read the Docs 2.7 documentation"), " ?"), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://docs.phpmyadmin.net/en/latest/"
  }, "Welcome to phpMyAdmin’s documentation! — phpMyAdmin 5.0.0-dev documentation")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://docs.python-guide.org/"
  }, "The Hitchhiker’s Guide to Python! — The Hitchhiker's Guide to Python")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://docs.python-requests.org/en/master/"
  }, "Requests: HTTP for Humans™ — Requests 2.21.0 documentation")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.writethedocs.org/"
  }, "Welcome to our community! — Write the Docs")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.writethedocs.org/blog/write-the-docs-2018-stats/"
  }, "Write the Docs 2018 Stats — Write the Docs")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://engineering.linecorp.com/ko/blog/write-the-docs-prague-2018-recap/"
  }, "테크니컬 라이팅 컨퍼런스: Write the Docs Prague 2018 방문기 - LINE ENGINEERING")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.writethedocs.org/organizer-guide/confs/start/"
  }, "I want to run a Write the Docs conference, now what? — Write the Docs")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.pycon.kr/2016apac/program/tutorial/4"
  }, "파이콘 APAC 2016 - Write the Docs Seoul Meetup #1 ")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.44bits.io/ko/post/why-should-i-use-docker-container"
  }, "왜 굳이 도커(컨테이너)를 써야 하나요? - 컨테이너를 사용해야 하는 이유 | 44bits.io")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://raccoonyy.github.io/docker-usages-for-dev-environment-setup/"
  }, "Docker (Compose) 활용법 - 개발 환경 구성하기")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://blog.docker.com/2014/10/docker-1-3-signed-images-process-injection-security-options-mac-shared-directories/"
  }, "Docker 1.3: signed images, process injection, security options, Mac shared directories - Docker Blog")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://deview.kr/2014/session?seq=20"
  }, "DEVIEW 2014 - Docker로 보는 클라우드 서버 운영의 미래")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://deview.kr/2013/detail.nhn?topicSeq=45"
  }, "DEVIEW 2013 - 이렇게 배포해야 할까? - Lightweight Linux Container Docker 를 활용하여 어플리케이션 배포하기")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://kubernetes.io/"
  }, "Production-Grade Container Orchestration - Kubernetes")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://aws.amazon.com/ko/fargate/"
  }, "AWS Fargate – 서버 또는 클러스터를 관리할 필요 없이 컨테이너를 실행")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.heroku.com/"
  }, "Cloud Application Platform | Heroku")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://12factor.net/"
  }, "The Twelve-Factor App")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://speakerdeck.com/koudaiii/v18-dot-12-number-containerdaysjp"
  }, "Kubernetes가 가져온 분산 시스템의 위협과의 싸움(Wantedly 사례, 일본어) - Speaker Deck")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.netlify.com/"
  }, "Netlify: All-in-one platform for automating modern web projects.")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/vaidik/sherlock"
  }, "vaidik/sherlock: Easy distributed locks for Python with a choice of backends.")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.reddit.com/r/ruby/comments/ad46f9/whining_ruby_evolution_is_taking_too_long/"
  }, "whining - Ruby evolution is taking TOO long : ruby")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.publickey1.jp/blog/19/ruby_2.html"
  }, "Rubyのまつもと氏、「気分を害することもある。だからどうか建設的であってほしい」 － Publickey")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://bugs.ruby-lang.org/issues/6284"
  }, "Feature #6284: Add composition for procs - Ruby trunk - Ruby Issue Tracking System")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://twitter.com/yukihiro_matz/status/1082418360501948416"
  }, "Yukihiro Matsumoto on Twitter: \"20+ years ago, ...\"")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://twitter.com/scari_net/status/1084747250150993920"
  }, "Younggun Kim on Twitter: \"이유가 무엇이든 ...\"")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://twitter.com/publickey/status/1083282277142999040"
  }, "Publickey on Twitter: \"ありがとうございます。...\"")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://rubyisdead.science/"
  }, "Ruby is dead | A totally legit site based on science")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://isrubydead.com/"
  }, "Is Ruby Dead?")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://jasoncharnes.com/using-ruby-in-2019/"
  }, "Using Ruby in 2019 - Jason Charnes")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://heartbleed.com/"
  }, "Heartbleed Bug")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://blog.trendmicro.com/trendlabs-security-intelligence/spyware-disguises-as-android-applications-on-google-play/"
  }, "Spyware Disguises as Android Applications on Google Play - TrendLabs Security Intelligence Blog")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
